import React, { useMemo, useEffect, useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Card, Typography, Divider, Checkbox } from 'antd';
import Cookies from 'js-cookie';
import ParticlesComponent from '../settings/particles';

const { Title } = Typography;

const Login = ({ onLogin }) => {
  const onFinish = (values) => {
    const { otp, remember } = values;
    // Simple hardcoded OTP authentication
    if (otp === '123456') {
      if (remember) {
        Cookies.set('isAuthenticated', 'true', { expires: 1 }); // Save cookie for 1 day
      }
      onLogin(true);
    } else {
      alert('Invalid OTP');
    }
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    const values = { otp: '123456', remember: true };
    onFinish(values);
  };

  const memoizedParticlesComponent = useMemo(() => <ParticlesComponent id="particles" />, []);

  return (
    <div className="App">
      {memoizedParticlesComponent}

      <Card
        title="Login"
        style={{ width: 400, margin: 'auto', marginTop: '100px' }}
      >
        <a href="https://work.andovar.com/" rel="noopener noreferrer">
          <Title level={3}>Andovar Studios:</Title>
          <h2>Work.Andovar</h2>
        </a>
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="otp"
            rules={[
              {
                required: true,
                message: 'Please input your OTP!',
                len: 6,
                pattern: /^[0-9]{6}$/,
                message: 'OTP must be 6 digits',
              },
            ]}
          >
            <Input prefix={<LockOutlined />} placeholder="OTP" />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember Me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
          <Form.Item>
            Andovar employee? Click <a href="#" onClick={handleLinkClick}>here</a> to access the platform
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;