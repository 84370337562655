import React from 'react';
import { Card, Typography, Divider } from 'antd';
import links from './links';
import './LinkCard.css'; // Import the CSS file

const { Paragraph } = Typography;

const groupLinks = (links) => {
  return links.reduce((acc, link) => {
    if (!acc[link.group]) {
      acc[link.group] = [];
    }
    acc[link.group].push(link);
    return acc;
  }, {});
};

const LinkCard = () => {
  const groupedLinks = groupLinks(links);

  return (
    <div>
      {Object.keys(groupedLinks).map((group, groupIndex) => (
        <div key={groupIndex}>
          <h2 style={{ textAlign: 'center' }}>{group}</h2>
          <div className="link-card-container">
            {groupedLinks[group].map((link, index) => (
              <Card
                key={index}
                title={
                  <span>
                    {link.icon} {link.title}
                  </span>
                }
                bordered
                onClick={() => window.open(link.url, '_blank')}
                style={{ cursor: 'pointer' }}
              >
                <Paragraph>{link.description}</Paragraph>
              </Card>
            ))}
          </div>
          {groupIndex < Object.keys(groupedLinks).length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
};

export default LinkCard;