import { 
  FileTextOutlined, AudioOutlined, SoundOutlined, 
  VideoCameraOutlined, CalculatorOutlined, ReadOutlined, 
  ScissorOutlined, FileSearchOutlined, UserOutlined 
} from '@ant-design/icons';

const links = [
  {
    title: 'SVO Portal',
    url: 'https://work.andovar.com/svo-jobs/',
    description: `This application helps you manage and control the quality of linguistic jobs.`,
    icon: <FileTextOutlined />,
    group: 'Audio Tools'
  },
  {
    title: 'Audio Cost Calculator',
    url: 'https://work.andovar.com/audio-cost-calculator',
    description: 'Audio Cost Calculator',
    icon: <CalculatorOutlined />,
    group: 'Audio Tools'
  },
  {
    title: 'Audio Script Editor',
    url: 'https://work.andovar.com/audio-script-editor/',
    description: 'This application is designed to help you edit and proofread audio scripts efficiently.',
    icon: <AudioOutlined />,
    group: 'Audio Tools'
  },
  {
    title: 'TTS Clone Engine',
    url: 'https://work.andovar.com/tts-clone-engine/',
    description: 'TTS Voice Cloning and Speech to Speech Cloning',
    icon: <SoundOutlined />,
    group: 'Audio Tools'
  },

  {
    title: 'Script Reader (Defined.ai)',
    url: 'https://work.andovar.com/script-reader',
    description: 'App created for Defined.ai file management',
    icon: <ReadOutlined />,
    group: 'Client Specific'
  },
  {
    title: 'Clip Manipulator',
    url: 'https://work.andovar.com/clip-manipulator',
    description: 'App to rename, trim, inject noise and more to audio clips',
    icon: <ScissorOutlined />,
    group: 'Audio Tools'
  },
  {
    title: 'Video Avatar',
    url: 'https://work.andovar.com/video-talking-heads',
    description: 'Avatar Synthesis Tool',
    icon: <VideoCameraOutlined />,
    group: 'Video Tools'
  },
  {
    title: 'Auto LM',
    url: 'https://work.andovar.com/autolm/',
    description: 'Transcribe and check audio with automated transcription.',
    icon: <FileSearchOutlined />,
    group: 'Audio Tools'
  },
  {
    title: 'Audio Transcription',
    url: 'https://work.andovar.com/qc-audio-transcription/',
    description: 'Transcribe and check audio',
    icon: <FileSearchOutlined />,
    group: 'Audio Tools'
  },
  {
    title: '32915_defined',
    url: 'https://work.andovar.com/32915_defined/',
    description: 'App created for Defined.ai 32915 Metadata file management.',
    icon: <FileSearchOutlined />,
    group: 'Client Specific'
  },
  {
    title: 'Voice Samples Hub',
    url: 'https://work.andovar.com/audio-samples-hub/',
    description: 'Platform for Voice Samples Management.',
    icon: <UserOutlined />,
    group: 'Audio Tools'
  },
  // Add more links as needed
];

export default links;