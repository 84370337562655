import React, { useEffect, useMemo, useState } from 'react';
import './css/App.css';
import Logo from './img/logo.png';
import { Card, Typography, Divider, FloatButton, Layout } from 'antd';
import ParticlesComponent from './settings/particles';
import LinkCard from './components/LinkCard';
import Login from './components/Login';
import Cookies from 'js-cookie';

const { Title } = Typography;

function App() {
  const memoizedParticlesComponent = useMemo(() => <ParticlesComponent id="particles" />, []);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Effect to check authentication status from cookies
  useEffect(() => {
    const authCookie = Cookies.get('isAuthenticated');
    if (authCookie === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  // Render login component if not authenticated and no query
  if (!isAuthenticated) {
    return <Login onLogin={setIsAuthenticated} />;
  }

  return (
    <div className="App">
      {memoizedParticlesComponent}
      <Card bordered>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <a href="https://work.andovar.com" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
            <Title level={3} style={{ marginRight: '10px' }}>Andovar Studio:</Title>
            <h2 style={{ marginTop: '28px' }}>Work Andovar Dashboard</h2>
          </a>
          <img src={Logo} alt="Andovar Studio Logo" className="logo" />
        </div>
        <Divider />
        <Card title={"Welcome to Andovar Studio"} bordered>
          Explore the dashboard to find various tools and resources to assist you in your work.
        </Card>
        <br />
        <LinkCard />
      </Card>
    </div>
  );
}

export default App;